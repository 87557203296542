import React from "react"
import TechnologyCategory from "../atoms/TechnologyCategory"
import TechnologyUsage from "../molecules/TechnologyUsage"
import Spacer from "../atoms/Spacer"
import styled from "styled-components"
import { ApplicationsList, EnvironmentsList } from "../../../data/TechnologiesList"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const UsagesList = styled.div`
    display: flex;
    width: 858px;
    flex-wrap: wrap;
`

const TechnologyUsages = () => {

    return (
        <Outer>
            <TechnologyCategory label="Application" />
            <UsagesList>
                {ApplicationsList.map((a, idx) => (
                    <TechnologyUsage key={'app' + idx} name={a.name} image={a.image} />
                ))}
            </UsagesList>
            <TechnologyCategory label="Environment" />
            <UsagesList>
                {EnvironmentsList.map((a, idx) => (
                    <TechnologyUsage key={'env' + idx} name={a.name} image={a.image} />
                ))}
            </UsagesList>
            <Spacer height="120px" />
        </Outer>
    )
}

export default TechnologyUsages