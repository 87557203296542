import { useStaticQuery, graphql } from "gatsby"

const TechnologyImages = (request) => {
    const data = useStaticQuery(graphql`
        query {
            techFigure0: file(relativePath: { eq: "PC/tech-figure0.png" }) {
                childImageSharp {
                    fixed(width: 876, height: 556) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            techFigure1: file(relativePath: { eq: "PC/tech-figure1.png" }) {
                childImageSharp {
                    fixed(width: 796, height: 552) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            arcore: file(relativePath: { eq: "PC/tech-icon-arcore.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            atom: file(relativePath: { eq: "PC/tech-icon-atom.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            awsec2: file(relativePath: { eq: "PC/tech-icon-awsec2.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            awss3: file(relativePath: { eq: "PC/tech-icon-awss3.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            awssns: file(relativePath: { eq: "PC/tech-icon-awssns.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            chainer: file(relativePath: { eq: "PC/tech-icon-chainer.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            circleci: file(relativePath: { eq: "PC/tech-icon-circleci.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            cordova: file(relativePath: { eq: "PC/tech-icon-cordova.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            docker: file(relativePath: { eq: "PC/tech-icon-docker.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            electron: file(relativePath: { eq: "PC/tech-icon-electron.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            firebase: file(relativePath: { eq: "PC/tech-icon-firebase.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            github: file(relativePath: { eq: "PC/tech-icon-github.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            jquery: file(relativePath: { eq: "PC/tech-icon-jquery.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            mongodb: file(relativePath: { eq: "PC/tech-icon-mongodb.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            postgresql: file(relativePath: { eq: "PC/tech-icon-postgresql.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            python: file(relativePath: { eq: "PC/tech-icon-python.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            rails: file(relativePath: { eq: "PC/tech-icon-rails.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            react: file(relativePath: { eq: "PC/tech-icon-react.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            redux: file(relativePath: { eq: "PC/tech-icon-redux.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            ruby: file(relativePath: { eq: "PC/tech-icon-ruby.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            slack: file(relativePath: { eq: "PC/tech-icon-slack.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            unity: file(relativePath: { eq: "PC/tech-icon-unity.png" }) {
                childImageSharp {
                    fixed(width: 83, height: 83) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
        }
    `)

    return data[request].childImageSharp.fixed
}

export default TechnologyImages
