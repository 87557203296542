import React from "react"
import styled from "styled-components"

const Outer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 40px;
    color: white;
    background: black;
    font-weight: 400;
    letter-spacing: 1.5px;
    margin: 40px 0px 20px 0px;
`

const Inner = styled.p`
    display: inline-block;
    line-height: 24px;
    font-weight: 400;
`

const TechnologyCategory = (props) => {

    return (
        <Outer>
            <Inner>
                {props.label}
            </Inner>
        </Outer>
    )
}

export default TechnologyCategory