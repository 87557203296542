import React from "react"
import styled from "styled-components"
import Spacer from "../../PC/atoms/Spacer"
import MobileTechnologyCategory from "../atoms/MobileTechnologyCategory"
import MobileTechnologyUsage from "../molecules/MobileTechnologyUsage"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { ApplicationsList, EnvironmentsList } from "../../../data/TechnologiesList"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const UsagesList = styled.div`
    display: flex;
    width: 320px;
    flex-wrap: wrap;
`

const UsagesListWide = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 560px;
`

const MobileTechnologyUsages = () => {

    const breakPoints = useBreakpoint()

    return (
        <Outer>
            <MobileTechnologyCategory label="Application" />
            {breakPoints.smartphone
                ?
                    <UsagesList>
                        {ApplicationsList.map((a, index) => (
                            <MobileTechnologyUsage key={'app' + index} name={a.name} image={a.image} />
                        ))}
                    </UsagesList>
                :
                    null
            }
            {breakPoints.mobileWide
                ?
                    <UsagesListWide>
                        {ApplicationsList.map((a, index) => (
                            <MobileTechnologyUsage key={'app' + index} name={a.name} image={a.image} />
                        ))}
                    </UsagesListWide>
                :
                    null
            }
            <MobileTechnologyCategory label="Environment" />
            {breakPoints.smartphone
                ?
                    <UsagesList>
                        {EnvironmentsList.map((a, index) => (
                            <MobileTechnologyUsage key={'env' + index} name={a.name} image={a.image} />
                        ))}
                    </UsagesList>
                :
                    null
            }
            {breakPoints.mobileWide
                ?
                    <UsagesListWide>
                        {EnvironmentsList.map((a, index) => (
                            <MobileTechnologyUsage key={'env' + index} name={a.name} image={a.image} />
                        ))}
                    </UsagesListWide>
                :
                    null
            }
            <Spacer height="50px" />
        </Outer>
    )
}

export default MobileTechnologyUsages