import React from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Outer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 30px;
    color: white;
    background: black;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 40px 0px 20px 0px;
    font-size: 14px;
`

const Inner = styled.p`
    display: inline-block;
    line-height: 24px;
`

const OuterWide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 45px;
    color: white;
    background: black;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 40px 0px 20px 0px;
    font-size: 20px;
`

const InnerWide = styled.div`
    display: inline-block;
    line-height: 24px;
`

const MobileTechnologyCategory = (props) => {

    const breakPoints = useBreakpoint()

    return (
        <React.Fragment>
            {breakPoints.smartphone
                ?
                    <Outer>
                        <Inner>
                            {props.label}
                        </Inner>
                    </Outer>
                :
                    null
            }
            {breakPoints.mobileWide
                ?
                    <OuterWide>
                        <InnerWide>
                            {props.label}
                        </InnerWide>
                    </OuterWide>
                :
                    null
            }

        </React.Fragment>
    )
}

export default MobileTechnologyCategory