import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import MobileTechnologyImages from "../../Images/MobileTechnologyImages"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import TechnologyImages from "../../Images/TechnologyImages"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 10px 10px 22px;
`

const Name = styled.p`
    font-size: 10px;
    font-weight: 400;
    margin: 10px 0px 0px 0px;
`

const WrapperWide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 22px 10px 33px;
`

const NameWide = styled.p`
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0px 0px 0px;
`

const MobileTechnologyUsage = (props) => {

    const breakPoints = useBreakpoint()

    return (
        <React.Fragment>
            {breakPoints.smartphone
                ?
                    <Wrapper>
                        <Img fixed={MobileTechnologyImages(props.image)} />
                        <Name>{props.name}</Name>
                    </Wrapper>
                :
                    null
            }
            {breakPoints.mobileWide
                ?
                    <WrapperWide>
                        <Img fixed={TechnologyImages(props.image)} />
                        <NameWide>{props.name}</NameWide>
                    </WrapperWide>
                :
                    null
            }

        </React.Fragment>
    )

}

export default MobileTechnologyUsage