import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Spacer from "../../PC/atoms/Spacer"
import MobileFluidImages from "../../Images/MobileFluidImages"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const Introduction = styled.p`
    display: inline-block;
    width: 80%;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 28px;
    margin: 50px 0px 50px 0px;
`

const MobileTechnologyExplanations = () => {

    return (
        <Outer>
            <Introduction>
                QUANDO（クアンド）は小売り、製造、金融等の「人・モノが活動するリアルな現場」の情報を取得し、機械学習やビックデータ分析技術を用いて、リアルタイムに分析・反映・制御を行う技術を開発しています。
                また、リアルタイム処理だけでなく、バッチ処理により長期的なデータトレンドを導き出し、ビジネスユーザーに対して様々な施策に繋がる情報を提供します。
                <br />
                すなわち、現場という、リアルで「アナログ」な環境を「デジタル」技術により変革する技術の開発を行っています。
            </Introduction>
            <Img fluid={MobileFluidImages('techFigure0')} style={{ width: '90%', maxWidth: '500px' }} />
            <Introduction>
                具体的には、処理のコア部分として、CNNやRNN等のディープラーニング技術、ログ解析や分散DB等のデータエンジニアリング、SQLや各種統計処理を用いたデータサイエンスを行うビッグデータ分析技術を開発しています。また、現場やユーザーに合わせて最適なインターフェースを提供する為、人へのUIとしてAR/VR技術, Web/Mobile App構築技術, センサーやカメラから情報を取得する為のIoT技術を開発しています。QUANDO（クアンド）では、エンジニアはハードからソフト、クライアントからサーバーの様に幅広い技術領域の開発に携わることができます。
                <br />
                お客様に対しては、データ収集から解析、制御、ビジネス分析に至るまで一気通貫の技術でビジネス価値の向上に寄与することができます。
            </Introduction>
            <Img fluid={MobileFluidImages('techFigure1')} style={{ width: '90%', maxWidth: '500px' }} />
            <Spacer height="40px" />
        </Outer>
    )
}

export default MobileTechnologyExplanations