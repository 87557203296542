import { useStaticQuery, graphql } from "gatsby"

const MobileTechnologyImages = (request) => {
    const data = useStaticQuery(graphql`
        query {
            arcore: file(relativePath: { eq: "PC/tech-icon-arcore.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            atom: file(relativePath: { eq: "PC/tech-icon-atom.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            awsec2: file(relativePath: { eq: "PC/tech-icon-awsec2.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            awss3: file(relativePath: { eq: "PC/tech-icon-awss3.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            awssns: file(relativePath: { eq: "PC/tech-icon-awssns.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            chainer: file(relativePath: { eq: "PC/tech-icon-chainer.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            circleci: file(relativePath: { eq: "PC/tech-icon-circleci.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            cordova: file(relativePath: { eq: "PC/tech-icon-cordova.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            docker: file(relativePath: { eq: "PC/tech-icon-docker.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            electron: file(relativePath: { eq: "PC/tech-icon-electron.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            firebase: file(relativePath: { eq: "PC/tech-icon-firebase.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            github: file(relativePath: { eq: "PC/tech-icon-github.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            jquery: file(relativePath: { eq: "PC/tech-icon-jquery.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            mongodb: file(relativePath: { eq: "PC/tech-icon-mongodb.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            postgresql: file(relativePath: { eq: "PC/tech-icon-postgresql.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            python: file(relativePath: { eq: "PC/tech-icon-python.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            rails: file(relativePath: { eq: "PC/tech-icon-rails.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            react: file(relativePath: { eq: "PC/tech-icon-react.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            redux: file(relativePath: { eq: "PC/tech-icon-redux.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            ruby: file(relativePath: { eq: "PC/tech-icon-ruby.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            slack: file(relativePath: { eq: "PC/tech-icon-slack.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            unity: file(relativePath: { eq: "PC/tech-icon-unity.png" }) {
                childImageSharp {
                    fixed(width: 45, height: 45) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
        }
    `)

    return data[request].childImageSharp.fixed
}

export default MobileTechnologyImages
