import React from "react"
import SEO from "../seo"
import Hero from "../PC/organisms/Hero"
import Pages from "../../data/PagesData"
import Header from "../PC/organisms/Header"
import Footer from "../PC/organisms/Footer"
import TechnologyExplanations from "../PC/organisms/TechnologyExplanations"
import TechnologyUsages from "../PC/organisms/TechnologyUsages"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import MobileTechnologyExplanations from "../mobile/organisms/MobileTechnologyExplanations"
import MobileTechnologyUsages from "../mobile/organisms/MobileTechnologyUsages"
import MobileHeader from "../mobile/organisms/MobileHeader"
import MobileFooter from "../mobile/organisms/MobileFooter"

const Template = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: auto;
`

const TechnologySEOData = {
    title: 'テクノロジー',
    lang: 'jp',
    url: '/technology',
    description:'QUANDOは小売り、製造、金融等の「人・モノが活動するリアルな現場」の情報を取得し、機械学習やビックデータ分析技術を用いて、リアルタイムに分析・反映・制御を行う技術を開発しています。',
}


const TechnologyTemplate = () => {

    const page = Pages[5]
    const breakPoints = useBreakpoint()

    return (
        <Template>
            <SEO
                title={TechnologySEOData.title}
                lang={TechnologySEOData.lang}
                url={TechnologySEOData.url}
                description={TechnologySEOData.description}
            />
            {breakPoints.pc
                ?
                    <React.Fragment>
                        <Hero page={page.name} />
                        <TechnologyExplanations />
                        <TechnologyUsages />
                        <Footer />
                        <Header page={page.name} />
                    </React.Fragment>
                :
                    null
            }
            {breakPoints.mobile
                ?
                    <React.Fragment>
                        <MobileTechnologyExplanations />
                        <MobileTechnologyUsages />
                        <MobileFooter />
                        <MobileHeader />
                    </React.Fragment>
                :
                    null
            }
        </Template>
    )
}

export default TechnologyTemplate