export const ApplicationsList = [
    {name: "Python", image: "python"},
    {name: "Chainer", image: "chainer"},
    {name: "Ruby", image: "ruby"},
    {name: "Rails", image: "rails"},
    {name: "React", image: "react"},
    {name: "Redux", image: "redux"},
    {name: "jQuery", image: "jquery"},
    {name: "MongoDB", image: "mongodb"},
    {name: "Cordova", image: "cordova"},
    {name: "Electron", image: "electron"},
    {name: "Firebase", image: "firebase"},
    {name: "PostgreSQL", image: "postgresql"},
    {name: "Unity", image: "unity"},
    {name: "ARCore", image: "arcore"},
]

export const EnvironmentsList = [
    {name: "AWS EC2", image: "awsec2"},
    {name: "AWS S3", image: "awss3"},
    {name: "AWS SNS", image: "awssns"},
    {name: "GitHub", image: "github"},
    {name: "Docker", image: "docker"},
    {name: "Atom", image: "atom"},
    {name: "CircleCI", image: "circleci"},
    {name: "Slack", image: "slack"},
]