import React from "react"
import Img from "gatsby-image"
import TechnologyImages from "../../Images/TechnologyImages"
import styled from "styled-components"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 30px 20px 30px;
`

const Name = styled.p`
    font-size: 14.5px;
    font-weight: 400;
    margin: 10px 0px 0px 0px;
`

const TechnologyUsage = (props) => {

    return (
        <Wrapper>
            <Img fixed={TechnologyImages(props.image)} />
            <Name>{props.name}</Name>
        </Wrapper>
    )

}

export default TechnologyUsage